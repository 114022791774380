import Swal from 'sweetalert2';
import { defineAsyncComponent } from 'vue';
import stateStore from "@/store";
import { availabeRole } from '@/helpers/access';
import dayjs from 'dayjs';
import { toastError } from '@/helpers/toastify';
import { beforeSave, checkBeforeCreating } from '@/api/pgi';

export default ({
	forms: {
		edit: {
			panel: {
				// height: '280px',
				width: '70%'
			},
			component: defineAsyncComponent(() => import('./forms/Edit.vue'))
		},
		list: {
			component: defineAsyncComponent(() => import('./forms/List.vue'))
		},
		select: {
			panel: {
				width: '90%'
			},
			config: {
				fields: {
					id: {
						config: {
							visible: false
						}
					},
					barcode: {
						description: 'Штрих-код исследования'
					},
					collection_date: {},
					biopsy_nmb: {
						config: {
							visible: false
						}
					},
					biopsy_nmb_till: {
						config: {
							visible: false
						}
					},
					number_biopsy: {},
					study: {},
					patient: {},
					notion: {
						config: {
							visible: false
						}
					}
				}
			}
		}
	},

	onBeforeDelete: async function (data: any) {
		if (availabeRole(['admin']) ? true : data.author_department == stateStore.state.user.department) {
			return true;
		} else {
			Swal.fire('Внимание', 'У Вас недостаточно прав для удаления данного исследования!', 'warning');

			return false;
		}
	},

	async checkBeforeRegister(data: any) {
		const { status, cutting, performer, performer_department, payment } = data;

		if (status == 2) data.processing_date = dayjs().format('YYYY-MM-DD HH:mm');

		if (data.processing_date) {
			for (const row of cutting) {
				if (!row.block && row.biopsy_nmb) {
					const response: any = await beforeSave({
						id: row.id,
						biopsy_nmb: row.biopsy_nmb,
						biopsy_nmb_till: row.biopsy_nmb_till,
						pieces: row.pieces,
						performer,
						performer_department,
						payment,
						year: dayjs(data.processing_date).year()
					})

					if (response.complete) {
						return true;
					} else {
						toastError(response.message);
					}

					return false;
				}
			}
		}

		return true;
	},

	onBeforeSave: async function (data: any, isNew: boolean) {
		if (isNew) {
			const response: any = await checkBeforeCreating({
				patient: data.patient,
				collection_date: data.collection_date
			});

			if (!response.complete) {
				const collection_date = data.collection_date ? `с датой забора ${dayjs(data.collection_date).format('DD.MM.YYYY')}` : '';

				const { value } = await Swal.fire({
					title: `Исследование пациента ${data._patient} ${collection_date} уже существует. Продолжить?`,
					showCancelButton: true,
					confirmButtonText: 'Да',
					cancelButtonText: 'Отмена'
				});

				return value;
			}
		}

		return this.checkBeforeRegister(data);
	}
})